.experience__container {
    display: grid;
    grid-template-columns: repeat(3);
    justify-content: center;
    padding: 1rem;

}

/*=================== ONE BLOCK ========================*/

.experience__container>div {
    background: var(--color-bg-variant);
    padding: 1rem;
    border-radius: 2rem;
    border: 1px solid var(--border-color);
}

/* .experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: pointer;
    transition: var(--transition);
} */


.experience__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.experience__details {
    text-align: center;
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align-last: center;
    transition: var(--transition);

}

.experience__details:hover {
    background: var(--color-bg);
    /* border-color: var(--color-primary-variant); */
    cursor: default;
}

.experience__details-icons {
    color: var(--color-primary);
}

.experience__levels{
    display: flex;
    margin: 0 auto;
    justify-content: center;
    gap: 1.5rem;
    
    color: var(--color-light);
}

.progress__bar{
    position: relative;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 1rem;
    background-color: grey;
    border-radius: 0.5em;
}

.progress__bar::before{
    content: attr(data-label);
    position: absolute;
    left: .25em;
    top: .25em;
    bottom: .25em;
    /* width: calc(var(--width) * 1%); */
    min-width: 2rem;
    max-width: calc(100% - 1em);
    background-color: var(--color-primary);
    border-radius: 1em;
}

.python::before{
    width: 80%;
}
.java::before{
    width: 40%;
}
.python__visual::before{
    width: 80%;
}
.power__bi::before{
    width: 40%;
}
.scikit::before{
    width: 80%;
}
.tensorflow::before{
    width: 60%;
}
.sql::before{
    width: 80%;
}
.aws::before{
    width: 60%;
}


/********** MEDIA QUERIES (MEDIUM DEVICES) **********/
@media screen and (max-width: 1024px) {

    .experience__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;

    }


}

/********** MEDIA QUERIES (SMALL DEVICES) **********/
@media screen and (max-width: 600px) {

    .experience__content {
        display: grid;
        grid-template-columns: 1fr;

    }

    .experience__details {
        padding: 1.5rem;

    }

    .experience__levels{
        width: 80%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        justify-content: center;
        gap: 0.8rem;
        padding-left: 1rem;
    }

}