.portfolio__container {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr); */
    gap: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
}


.portfolio__item {

    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid var(--border-color);
    transition: var(--transistions);

}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;

}

.portfolio__item-img {
    display: flex;
    width: 350px;
    height: 200px;
    border-radius: 1rem;
    overflow: hidden;
}

.portfolio__item-img img{
    object-fit: fill;
}

.portfolio__item h3 {
    margin: 1rem 0 1.2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.tools__used{
    padding-bottom: 0.5rem;
    color: var(--color-light);
}


/********** MEDIA QUERIES (MEDIUM DEVICES) **********/
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

    .portfolio__item-img {
        display: flex;
        width: 250px;
        height: 150px;
        border-radius: 1rem;
        overflow: hidden;
    }

}

/********** MEDIA QUERIES (SMALL DEVICES) **********/
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}