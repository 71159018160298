header {
    height: 100vh;
    padding-top: 3rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/*========================= CTA ============================*/
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/*========================= HEADER SOCIALS ============================*/
.headerSocials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.headerSocials::after {
    content: "";
    width: 2px;
    height: 2rem;
    background: var(--color-primary);
}


/*========================= ME ============================*/
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;

}

.me img {
    position: absolute;
    width: 30rem;
    left: calc(50% - 15rem);
}

/*=================== SCROLL DOWN =====================*/
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
}



/* ********* MEDIA QUERIES (14' LAPTOP) ********* */
@media screen and (max-width: 1023px) {
    .me {
        margin-top: 2rem;

        overflow: hidden;
        padding: 2rem 1.5rem 1.5rem 1.5rem;

    }

    .cta {
        margin-top: 1.5rem;
    }

}


/********** MEDIA QUERIES (MEDIUM DEVICES) **********/
@media screen and (max-width: 1024px) {
    header {
        height: 75vh;
    }

}

/********** MEDIA QUERIES (OTHER MEDIUM DEVICES) **********/
@media screen and (max-width: 800px) {
    header {
        height: 85vh;
    }


    .me {
        margin-top: 3rem;
        padding: 5rem 1.5rem 1.5rem 1.5rem;

    }
}

/********** MEDIA QUERIES (SMALL DEVICES) **********/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .headerSocials,
    .scroll__down {
        display: none;
    }

}

/********** MEDIA QUERIES (SUPA SMALL DEVICES) **********/
@media screen and (max-width: 400px) and (max-height: 700px) {
    header {
        height: 135vh;
    }

    .headerSocials,
    .scroll__down {
        display: none;
    }

}

/********** MEDIA QUERIES (S8 SMALL DEVICES) **********/
@media screen and (max-width: 400px) and (min-height: 701px) {
    header {
        height: 120vh;
    }


    .headerSocials,
    .scroll__down {
        display: none;
    }

}

/********** MEDIA QUERIES (P9 SMALL DEVICES) **********/
@media screen and (max-width: 400px) and (max-height: 600px) {
    header {
        height: 150vh;
    }


    .headerSocials,
    .scroll__down {
        display: none;
    }

}

/********** MEDIA QUERIES (iPhone 12 Pro SMALL DEVICES) **********/
@media screen and (max-width: 420px) and (min-height: 800px) {
    header {
        height: 100vh;
    }


    .headerSocials,
    .scroll__down {
        display: none;
    }

}


/********** MEDIA QUERIES (iPhone 6/7/8 Pro SMALL DEVICES) **********/
@media screen and (min-width: 405px) and (max-height: 780px) {
    header {
        height: 115vh;
    }


    .headerSocials,
    .scroll__down {
        display: none;
    }

}