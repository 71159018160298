@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
header {
    height: 100vh;
    padding-top: 3rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/*========================= CTA ============================*/
.cta {
    margin-top: 2.5rem;
    display: flex;
    grid-gap: 1.2rem;
    gap: 1.2rem;
    justify-content: center;
}

/*========================= HEADER SOCIALS ============================*/
.headerSocials {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.headerSocials::after {
    content: "";
    width: 2px;
    height: 2rem;
    background: var(--color-primary);
}


/*========================= ME ============================*/
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;

}

.me img {
    position: absolute;
    width: 30rem;
    left: calc(50% - 15rem);
}

/*=================== SCROLL DOWN =====================*/
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}



/* ********* MEDIA QUERIES (14' LAPTOP) ********* */
@media screen and (max-width: 1023px) {
    .me {
        margin-top: 2rem;

        overflow: hidden;
        padding: 2rem 1.5rem 1.5rem 1.5rem;

    }

    .cta {
        margin-top: 1.5rem;
    }

}


/********** MEDIA QUERIES (MEDIUM DEVICES) **********/
@media screen and (max-width: 1024px) {
    header {
        height: 75vh;
    }

}

/********** MEDIA QUERIES (OTHER MEDIUM DEVICES) **********/
@media screen and (max-width: 800px) {
    header {
        height: 85vh;
    }


    .me {
        margin-top: 3rem;
        padding: 5rem 1.5rem 1.5rem 1.5rem;

    }
}

/********** MEDIA QUERIES (SMALL DEVICES) **********/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .headerSocials,
    .scroll__down {
        display: none;
    }

}

/********** MEDIA QUERIES (SUPA SMALL DEVICES) **********/
@media screen and (max-width: 400px) and (max-height: 700px) {
    header {
        height: 135vh;
    }

    .headerSocials,
    .scroll__down {
        display: none;
    }

}

/********** MEDIA QUERIES (S8 SMALL DEVICES) **********/
@media screen and (max-width: 400px) and (min-height: 701px) {
    header {
        height: 120vh;
    }


    .headerSocials,
    .scroll__down {
        display: none;
    }

}

/********** MEDIA QUERIES (P9 SMALL DEVICES) **********/
@media screen and (max-width: 400px) and (max-height: 600px) {
    header {
        height: 150vh;
    }


    .headerSocials,
    .scroll__down {
        display: none;
    }

}

/********** MEDIA QUERIES (iPhone 12 Pro SMALL DEVICES) **********/
@media screen and (max-width: 420px) and (min-height: 800px) {
    header {
        height: 100vh;
    }


    .headerSocials,
    .scroll__down {
        display: none;
    }

}


/********** MEDIA QUERIES (iPhone 6/7/8 Pro SMALL DEVICES) **********/
@media screen and (min-width: 405px) and (max-height: 780px) {
    header {
        height: 115vh;
    }


    .headerSocials,
    .scroll__down {
        display: none;
    }

}
.about__container {
    display: grid;
    grid-template-columns: 35% 50%;
    grid-gap: 10%;
    gap: 10%;

}

.about__me {
    width: 70%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg,
            transparent,
            var(--color-primary),
            transparent);

}

.about__me-img {
    border-radius: 2rem;
    overflow: hidden;
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
    transition: all 0.3s ease;
}

.about__me-img img {
    height: 400px;

}

.about__me-img:hover {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid var(--border-color);
    border-radius: 1rem;
    padding: 2rem;
    text-align-last: center;
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about__icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1px;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

/********** MEDIA QUERIES (14' LAPTOP DEVICES) **********/
@media screen and (max-width: 1600px) {

    .about__container {        
        grid-template-columns: 40% 50%;
        grid-gap: 10%;
        gap: 10%;
    
    }

    .about__me {
        width: 85%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }


}

/********** MEDIA QUERIES (14' LAPTOP DEVICES) **********/
@media screen and (max-width: 1050px) {

    .about__container {
        width: 95%;
    }


    .about__me {
        width: 100%;
        margin: 0 auto 4rem;
    }

}


/********** MEDIA QUERIES (MEDIUM DEVICES) **********/
@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        grid-gap: 0;
        gap: 0;

    }

    .about__me {
        width: 45%;
        margin: 2rem auto 4rem;
    }

    .about__content p {
        margin: 1rem 0 1.5rem;
    }

}


/********** MEDIA QUERIES (SMALL DEVICES) **********/
@media screen and (max-width: 900px) {

    .about__me {
        width: 50%;
        margin: 0 auto 3rem;

    }


    .about__content {
        text-align: center;
    }

    .about__contect p {
        margin: 1.5rem 0;
    }

}


/********** MEDIA QUERIES (SMALL DEVICES) **********/
@media screen and (max-width: 600px) {

    .about__me {
        width: 90%;
        margin: 0 auto 3rem;

    }

    .about__cards {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        gap: 1rem;
    }

    .about__content {
        text-align: center;
    }

    .about__contect p {
        margin: 1.5rem 0;
    }

}
nav {
    background: rgba(0, 0, 0, 0.3);
    width: -webkit-max-content;
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    border-radius: 3rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    bottom: 2rem;
    display: flex;
    grid-gap: 0.8rem;
    gap: 0.8rem;
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
}

nav a {
    background: transparent;
    padding: 0.8rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

nav a:hover {
    background: rgba(0, 0, 0, 0.5);
}

nav a.active {
    background: var(--color-primary);
    color: var(--color-bg);
}
.experience__container {
    display: grid;
    grid-template-columns: repeat(3);
    justify-content: center;
    padding: 1rem;

}

/*=================== ONE BLOCK ========================*/

.experience__container>div {
    background: var(--color-bg-variant);
    padding: 1rem;
    border-radius: 2rem;
    border: 1px solid var(--border-color);
}

/* .experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: pointer;
    transition: var(--transition);
} */


.experience__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.5rem;
    gap: 1.5rem;
}

.experience__details {
    text-align: center;
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align-last: center;
    transition: var(--transition);

}

.experience__details:hover {
    background: var(--color-bg);
    /* border-color: var(--color-primary-variant); */
    cursor: default;
}

.experience__details-icons {
    color: var(--color-primary);
}

.experience__levels{
    display: flex;
    margin: 0 auto;
    justify-content: center;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    
    color: var(--color-light);
}

.progress__bar{
    position: relative;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 1rem;
    background-color: grey;
    border-radius: 0.5em;
}

.progress__bar::before{
    content: attr(data-label);
    position: absolute;
    left: .25em;
    top: .25em;
    bottom: .25em;
    /* width: calc(var(--width) * 1%); */
    min-width: 2rem;
    max-width: calc(100% - 1em);
    background-color: var(--color-primary);
    border-radius: 1em;
}

.python::before{
    width: 80%;
}
.java::before{
    width: 40%;
}
.python__visual::before{
    width: 80%;
}
.power__bi::before{
    width: 40%;
}
.scikit::before{
    width: 80%;
}
.tensorflow::before{
    width: 60%;
}
.sql::before{
    width: 80%;
}
.aws::before{
    width: 60%;
}


/********** MEDIA QUERIES (MEDIUM DEVICES) **********/
@media screen and (max-width: 1024px) {

    .experience__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
        gap: 1.5rem;

    }


}

/********** MEDIA QUERIES (SMALL DEVICES) **********/
@media screen and (max-width: 600px) {

    .experience__content {
        display: grid;
        grid-template-columns: 1fr;

    }

    .experience__details {
        padding: 1.5rem;

    }

    .experience__levels{
        width: 80%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        justify-content: center;
        grid-gap: 0.8rem;
        gap: 0.8rem;
        padding-left: 1rem;
    }

}
.portfolio__container {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr); */
    grid-gap: 2.5rem;
    gap: 2.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
}


.portfolio__item {

    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid var(--border-color);
    transition: var(--transistions);

}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;

}

.portfolio__item-img {
    display: flex;
    width: 350px;
    height: 200px;
    border-radius: 1rem;
    overflow: hidden;
}

.portfolio__item-img img{
    object-fit: fill;
}

.portfolio__item h3 {
    margin: 1rem 0 1.2rem;
}

.portfolio__item-cta {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 1rem;
}

.tools__used{
    padding-bottom: 0.5rem;
    color: var(--color-light);
}


/********** MEDIA QUERIES (MEDIUM DEVICES) **********/
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.2rem;
        gap: 1.2rem;
    }

    .portfolio__item-img {
        display: flex;
        width: 250px;
        height: 150px;
        border-radius: 1rem;
        overflow: hidden;
    }

}

/********** MEDIA QUERIES (SMALL DEVICES) **********/
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        gap: 1rem;
    }
}
.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    grid-gap: 12%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    grid-gap: 1.2rem;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid var(--border-color);
    transition: var(--transition);
}

.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

/*========================= FORM =============================*/

form {
    display: flex;
    flex-direction: column;
    grid-gap: 1.2rem;
    gap: 1.2rem;
}

input,
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: white;
}


/********** MEDIA QUERIES (MEDIUM DEVICES) **********/
@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        grid-gap: 2rem;
        gap: 2rem;
    }

}

/********** MEDIA QUERIES (SMALL DEVICES) **********/
@media screen and (max-width: 600px) {
    .container.contact__container {
        width: var(--container-width-sd);
    }


}
footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a {
    color: var(--color-bg);
}

.footer__logo {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: inline-block;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 2rem;
    gap: 2rem;
    margin: 0 auto 3rem;

}

.footersocials {
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footersocials a {
    background: var(--color-bg);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}

.footersocials a:hover {
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg);
}

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-bg);
}


/********** MEDIA QUERIES (MEDIUM DEVICES) **********/
@media screen and (max-width: 1024px) {}

/********** MEDIA QUERIES (SMALL DEVICES) **********/
@media screen and (max-width: 600px) {

    .permalinks {
        flex-direction: column;
        grid-gap: 1.5rem;
        gap: 1.5rem;
    }

    .footersocials {
        margin-bottom: 2.6rem;
    }
}
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    /*
    global variables
    */

    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);


    /* --color-bg: rgb(243, 243, 243);
    --color-bg-variant: rgb(228, 228, 228);
    --color-primary: rgba(63, 63, 63, 0.63);
    --color-primary-variant: rgb(131, 131, 131);
    --color-white: rgb(0, 0, 0);
    --color-light: grey; */

    --border-color: var(--color-primary); 

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sd: 90%;

    --transition: all 0.2s ease;

}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: #1f1f38;
    background: var(--color-bg);
    color: #fff;
    color: var(--color-white);
    line-height: 1.7;

    /* if i want to add a backround image or texture */
    /* background-image: url(./assets/whozoom.jpg); */

}

/*================GENEARL STYLES===============*/

.container {
    width: 75%;
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 3rem;
}

section>h2,
section>h5 {
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    color: var(--color-light);
}

section>h2 {
    color: #4db5ff;
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: rgba(255, 255, 255, 0.6);
    color: var(--color-light);
}

a {
    /* color: black; */
    color: #4db5ff;
    color: var(--color-primary);
    transition: var(--transistion);
}

a:hover {
    color: whitesmoke;
}

.btn {
    width: -webkit-max-content;
    width: max-content;
    display: inline-block;
    color: #4db5ff;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-bottom: 0.4rem;
    cursor: pointer;
    border: 1px solid #4db5ff;
    border: 1px solid var(--color-primary);
    transition: var(--transistion);
    border-radius: 0.5rem;
}

.btn:hover {
    background: #fff;
    background: var(--color-white);
    color: #1f1f38;
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: #4db5ff;
    background: var(--color-primary);
    color: #1f1f38;
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}



/********** MEDIA QUERIES (MEDIUM DEVICES) **********/
@media screen and (max-width: 1024px) {

    .container {
        width: 86%;
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/********** MEDIA QUERIES (SMALL DEVICES) **********/
@media screen and (max-width: 600px) {
    .container {
        width: 90%;
        width: var(--container-width-sd);
    }

    section>h2 {
        margin-bottom: 2rem;
    }
}
